import RenameFirewall from '@/layouts/Stack/components/RenameFirewall';
import showErrorModal from '@/mixins/showErrorModal';
import Vue from 'vue';

export default {
  mixins: [showErrorModal],
  computed: {
    id() {
      return this.tariff.id;
    },
  },
  methods: {
    renameFirewall: function (instance, item) {
      const that = this;
      return new Promise(() => {
        const selfName = 'RenameFirewall';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: RenameFirewall,
          closeOnBackdrop: false,
          props: {
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            instance: data => {
              instance = data;
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  this.renameNameFirewall(instance, item)
                    .then(data => {
                      if (data === instance) {
                        this.$modals.close({ name: selfName });
                        this.$store.dispatch(
                          'moduleStack/fetchOpenStackRequestFirewalls',
                          'firewalls'
                        );
                        this.showResModal('Файрвол успешно переименован');
                      }
                    })
                    .catch(e => this.showError(e));
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    renameNameFirewall(name, item) {
      // console.log(item.action);
      return this.$store.dispatch('moduleStack/updateFirewall', {
        firewall: {
          name: name,
        },
        id: item.id,
        item: 'name',
      });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        cancel: {
          props: { title: this.$t('close') },
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
  },
};
