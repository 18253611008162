import NewFirewall from '@/layouts/Stack/components/NewFirewall';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';

export default {
  mixins: [showErrorModal, storeMixin],
  data() {
    return {
      data: {},
      isProcessing: false,
    };
  },
  computed: {
    // quota() {
    //   return this.$store.getters['moduleStack/aviableSpaseVolume'];
    // },
    // network() {
    //   return this.$store.state.moduleStack.networks.filter(x => x['router:external'] === true);
    // },
  },
  methods: {
    newFirewall: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'NewFirewall';
        this.$modals.open({
          name: selfName,
          size: 'small',
          component: NewFirewall,
          closeOnBackdrop: false,
          props: {
            // instance: this.network,
          },
          text: this.$t('sure.text'),
          on: {
            change: data => {
              instance = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  this.createFirewall(instance)
                    .then(async () => {
                      // console.log(data);
                      await this.$store.dispatch(
                        'moduleStack/fetchOpenStackRequestFirewalls',
                        'firewalls'
                      );
                      that.$modals.close();
                      this.showResModal(this.$t('success'));
                    })
                    .catch(e => {
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    createFirewall(payload) {
      return this.$store.dispatch('moduleStack/createFirewall', payload).catch(e => {
        this.showError(e);
      });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        confirm: {
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
  },
};
